import { CallTwoTone, FiberSmartRecordTwoTone, InventoryTwoTone } from '@mui/icons-material'
import duotone from 'icons/duotone'

export const navigations = [
  {
    type: 'label',
    label: 'General',
  },
  {
    name: 'Products',
    icon: InventoryTwoTone,
    path: '/dashboard/products',
  },
  {
    name: 'Overview',
    icon: duotone.Dashboard,
    path: '/dashboard/overview',
  },
  {
    name: 'Prediction',
    icon: FiberSmartRecordTwoTone,
    path: '/dashboard/prediction',
  },
  {
    type: 'label',
    label: 'Analytics',
  },
  {
    name: 'My Dashboard 1',
    path: '/dashboard/custom-dashboard/1',
    badge: {
      value: 'new',
    },
  },
  {
    type: 'label',
    label: 'Tools',
  },
  {
    name: 'Goals',
    icon: duotone.TodoList,
    path: '/dashboard/goals', // children: [{ name: 'Data Table', path: '/dashboard/data-table-v2' }],
  },
  {
    name: 'Reports',
    icon: duotone.DataTable,
    path: '/dashboard/reports', // children: [{ name: 'Data Table', path: '/dashboard/data-table-v2' }],
  },
  {
    name: 'Sources',
    icon: duotone.DiagramProject,
    path: '/dashboard/sources', // children: [{ name: 'Data Table', path: '/dashboard/data-table-v2' }],
  },
  {
    type: 'label',
    label: 'Support',
  },
  {
    name: 'Contact Us',
    icon: CallTwoTone,
    path: '/dashboard/todo-list',
  },
  {
    name: 'Documentation',
    icon: duotone.FileCircleQuestion,
    type: 'extLink',
    path: 'https://uko-react-doc.vercel.app/',
  },
]
