import Accounts from "./Accounts";
import AdminEcommerce from "./AdminEcommerce";
import ArrowLeftToLine from "./ArrowLeftToLine";
import BadgeDollar from "./BadgeDollar";
import Calender from "./Calender";
import Chat from "./Chat";
import CommentsQuestionCheck from "./CommentsQuestionCheck";
import Dashboard from "./Dashboard";
import { default as DataTable, default as TableList } from "./DataTable";
import DiagramProject from "./DiagramProject";
import Ecommerce from "./Ecommerce";
import ElementHub from "./ElementHub";
import FileCircleQuestion from "./FileCircleQuestion";
import Invoice from "./Invoice";
import LayerGroup from "./LayerGroup";
import Logout from "./Logout";
import MessagesDollar from "./MessagesDollar";
import Pages from "./Pages";
import PersonChalkboard from "./PersonChalkboard";
import PersonCircleCheck from "./PersonCircleCheck";
import Pricing from "./Pricing";
import ProjectChart from "./ProjectChart";
import RectangleCirclePlus from "./RectangleCirclePlus";
import Session from "./Session";
import Settings from "./Settings";
import TodoList from "./TodoList";
import UserList from "./UserList"; // eslint-disable-next-line import/no-anonymous-default-export
import UserProfile from "./UserProfile";

const DuoToneIcons = {
  AdminEcommerce,
  ArrowLeftToLine,
  CommentsQuestionCheck,
  Dashboard,
  DiagramProject,
  Ecommerce,
  ElementHub,
  FileCircleQuestion,
  Logout,
  ProjectChart,
  Settings,
  UserProfile,
  UserList,
  TableList,
  Accounts,
  Calender,
  Chat,
  Invoice,
  TodoList,
  Session,
  Pages,
  Pricing,
  DataTable,
  LayerGroup,
  MessagesDollar,
  BadgeDollar,
  RectangleCirclePlus,
  PersonChalkboard,
  PersonCircleCheck,
};

export default DuoToneIcons;
