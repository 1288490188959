import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { JWTAuthProvider } from 'contexts/JWTAuth'
import SettingsProvider from 'contexts/settingsContext'
import hr from 'date-fns/locale/hr'
import 'dayjs/locale/de'
import 'nprogress/nprogress.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-quill/dist/quill.snow.css'
import 'simplebar-react/dist/simplebar.min.css'
import App from './App'
import './__fakeData__'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hr}>
      <SettingsProvider>
        <JWTAuthProvider>
          <App />
        </JWTAuthProvider>
      </SettingsProvider>
    </LocalizationProvider>
  </React.StrictMode>
)
